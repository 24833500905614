import React ,{ useState , useEffect } from 'react'
import Request from '../API/Requests'
import { setAuth } from '../store/authSlice'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'


export default function UserModalEdit({ editUserData , seteditUserData }) {

    const { user } = useSelector((state)=> state.auth); 

    const dispatch = useDispatch();
    const [countryData, setcountryData] = useState([])
    const [wareHouseData, setwareHouseData] = useState([])
    // This state will have warehouse list based on Country
    const [country_warehouse, setcountry_warehouse] = useState(editUserData.wareHouseArray)

    useEffect(() => {
      
        Request.GetAllCountry().then((result)=>{
            setcountryData(result.data.data)
               


            
        Request.GetAllWareHouse().then((res)=>{
            setwareHouseData(res.data.data)
            
            // onChangeCountry_Warehouse({id:editUserData.country_id , wareHouseData:res.data.data})
        }).catch((error) => {
            if(error.response.status === 401){
                dispatch(setAuth({user:null, access_token:null }))
                // dispatch(setLoading({ IsLoading: false }));
              }else{
            
      
                console.log(error)
                alert("Something went wrong")
                }
        })










        }).catch((error) => {
            if(error.response.status === 401){
                dispatch(setAuth({user:null, access_token:null }))
                // dispatch(setLoading({ IsLoading: false }));
              }else{
            
      
                console.log(error)
                alert("Something went wrong")
                }
        })

    
     
    }, [])


    const onClickSaveChanges = () => {


         console.log(editUserData)

         if(editUserData.confirm_password !== editUserData.password){
               alert("Password and Confirm Password do not match")
               return
         }

         Request.EditUser({ id:editUserData.userid, data:editUserData}).then((result)=>{
            window.location.reload(false)
            // window.location.reload(false)
         }).catch((error) => {
            if(error.response.status === 401){
                dispatch(setAuth({user:null, access_token:null }))
                // dispatch(setLoading({ IsLoading: false }));
              }else{
            
      
                console.log(error)
                alert(error.response.data.error)
                }
         })
    }

    const onChangestaffId = (e)=>{
        seteditUserData((prev)=>{
            return {
                wareHouseArray: prev.wareHouseArray,
        userid: prev.userid,
        first_name:prev.first_name,
        last_name:prev.last_name,
        staff_id:e.target.value,
        country_id:prev.country_id,
        warehouse_id:prev.warehouse_id,
        password:prev.password,
        role:prev.role,
        confirm_password:prev.confirm_password,
            }
        });
  }

  const onChangeFirstName = (e)=>{
    seteditUserData((prev)=>{
        return {
            wareHouseArray: prev.wareHouseArray,
            userid: prev.userid,
            first_name:e.target.value,
    last_name:prev.last_name,
    staff_id:prev.staff_id,
    country_id:prev.country_id,
    warehouse_id:prev.warehouse_id,
    password:prev.password,
    role:prev.role,
    confirm_password:prev.confirm_password,
        }
    });
  }
  
  const onChangeLastName= (e)=>{
    seteditUserData((prev)=>{
        return {
            wareHouseArray: prev.wareHouseArray,
            userid: prev.userid,
            first_name:prev.first_name,
    last_name:e.target.value,
    staff_id:prev.staff_id,
    country_id:prev.country_id,
    warehouse_id:prev.warehouse_id,
    password:prev.password,
    role:prev.role,
    confirm_password:prev.confirm_password,
        }
    });
  }
 
  const onChangeCountryId = (e)=>{
    
    seteditUserData((prev)=>{
        return {
            wareHouseArray: onChangeCountry_Warehouse({ id:e.target.value , wareHouseData:wareHouseData }),
            userid: prev.userid,
            first_name:prev.first_name,
    last_name:prev.last_name,
    staff_id:prev.staff_id,
    country_id:e.target.value,
    warehouse_id:'',
    password:prev.password,
    role:prev.role,
    confirm_password:prev.confirm_password,
        }
    });
  }

  const onChangeWareHouseId = (e)=>{
    seteditUserData((prev)=>{
        return {
            wareHouseArray: prev.wareHouseArray,
            userid: prev.userid,
            first_name:prev.first_name,
    last_name:prev.last_name,
    staff_id:prev.staff_id,
    country_id:prev.country_id,
    warehouse_id:e.target.value,
    password:prev.password,
    role:prev.role,
    confirm_password:prev.confirm_password,
        }
    });
  }

  const onChangePassword = (e)=>{
    seteditUserData((prev)=>{
        return {
            wareHouseArray: prev.wareHouseArray,
            userid: prev.userid,
            first_name:prev.first_name,
    last_name:prev.last_name,
    staff_id:prev.staff_id,
    country_id:prev.country_id,
    warehouse_id:prev.warehouse_id,
    password:e.target.value,
    role:prev.role,
    confirm_password:prev.confirm_password,
        }
    });
  }
   
  const onChangeConfirmPassword = (e)=>{
    seteditUserData((prev)=>{
        return {
            wareHouseArray: prev.wareHouseArray,
            userid: prev.userid,
            first_name:prev.first_name,
    last_name:prev.last_name,
    staff_id:prev.staff_id,
    country_id:prev.country_id,
    warehouse_id:prev.warehouse_id,
    password:prev.password,
    role:prev.role,
    confirm_password:e.target.value,
        }
    });
  }


  const onChangeRole = (e)=>{
    seteditUserData((prev)=>{
        return {
            wareHouseArray: prev.wareHouseArray,
            userid: prev.userid,
            first_name:prev.first_name,
    last_name:prev.last_name,
    staff_id:prev.staff_id,
    country_id:prev.country_id,
    warehouse_id:prev.warehouse_id,
    password:prev.password,
    role:e.target.value,
    confirm_password:prev.confirm_password,
        }
    });
  }


  const onChangeCountry_Warehouse= ({ id ,wareHouseData })=>{
    
    console.log(id,"ID")
    console.log(wareHouseData, "wareHouseData")
      
 
         let country_Based_warehouse_ = [];
         
         wareHouseData.forEach((ele,i)=>{
           
            if(ele.country_id == id){
               
                country_Based_warehouse_.push(ele)
            }
         })

        
         
         seteditUserData((prev)=>{
            return {
        wareHouseArray:country_Based_warehouse_,
        userid: prev.userid,
        first_name:prev.first_name,
        last_name:prev.last_name,
        staff_id:prev.staff_id,
        country_id:prev.country_id,
        warehouse_id:prev.warehouse_id,
        password:prev.password,
        role:prev.role,
        confirm_password:prev.confirm_password,
            }
         });
         
    }


  return (
    <div className="modal fade" id="usermasteredit" tabIndex="-1" aria-labelledby="usermaster" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">Edit</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label htmlFor="staffid" className="form-label">Staff ID</label>
                                    <input type="text" value={editUserData.staff_id} onChange={onChangestaffId} className="form-control" id="staffid" placeholder=""/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label htmlFor="firstandlastname" className="form-label"> First name</label>
                                    <input type="text" value={editUserData.first_name} onChange={onChangeFirstName} className="form-control" id="firstandlastname" placeholder=""/>
                                </div>
                            </div>
                            <div className="col-md-6">
                            <div className="mb-2">
                                <label htmlFor="firstandlastname" className="form-label"> Last name</label>
                                <input type="text" value={editUserData.last_name} onChange={onChangeLastName} className="form-control" id="firstandlastname" placeholder=""/>
                            </div>
                        </div>
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label htmlFor="sountry" className="form-label">Country</label>


                                    {(user.role === 'admin')?(  <select disabled value={user.country_id}   className="form-select" aria-label="Default select example">
                                    {
                                        countryData.map((ele,i)=>{
                                            return <option key={i} value={ele.id}>{ele.country_name}</option>
                                        })
                                    }
                                    
                                </select>):(  <select value={editUserData.country_id} onChange={onChangeCountryId} className="form-select" aria-label="Default select example">
                                          {
                                              countryData.map((ele,i)=>{
                                                  return <option key={i} value={ele.id}>{ele.country_name}</option>
                                              })
                                          }
                                          
                                      </select>)}
                                   
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label htmlFor="warehouse" className="form-label">Warehouse</label>
                                    <select value={editUserData.warehouse_id} onChange={onChangeWareHouseId}  className="form-select" aria-label="Default select example">
                                    {
                                        <option  value={" "}>{" "}</option>
                                    }
                                    {

                                        
                                        editUserData.wareHouseArray.map((ele,i)=>{
                                            return <option key={i} value={ele.id}>{ele.name}</option>
                                        })
                                    }
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label htmlFor="password" className="form-label">Password</label>
                                    <input value={editUserData.password}  onChange={onChangePassword}type="text" className="form-control" id="password" placeholder=""/>
                                </div>
                            </div>
                            <div className="col-md-6">
                            <div className="mb-2">
                                <label htmlFor="password" className="form-label">Confirm Password</label>
                                <input value={editUserData.confirm_password} onChange={onChangeConfirmPassword} type="text" className="form-control" id="password" placeholder=""/>
                            </div>
                        </div>
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label htmlFor="role" className="form-label">Role</label>
                                    <select value={editUserData.role} onChange={onChangeRole} className="form-select" aria-label="Default select example">
                                            <option value={""} ></option>
                                          <option value={"admin"} >Admin</option>
                                          <option value={"staff"} >Staff</option>
                                    </select>
                                    
                                </div>
                            </div>                            
                        </div>
                    </div>
                    <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button onClick={onClickSaveChanges} type="button" className="btn btn-primary">Save changes</button>
                    </div>
                </div>
            </div>
        </div>
  )
}
