import React from 'react'
import { endpoint } from '../API/endpoint'
import { useSelector } from 'react-redux'
const ExcelURL = `${endpoint.BaseUrl}${endpoint.wareHouseDataExcel}`

export default function Warehouseaddexcel() {
  const {user} = useSelector((state)=> state.auth)

  return (
    <div className="rightme">
      <div className="eab">
        <button className="excel"><a href={`${endpoint.BaseUrl}${endpoint.wareHouseDataExcel}?country_id=${user.role === 'super_admin'?0:user.country_id}`} > Excel  </a></button>
        <button className="addnew" data-bs-toggle="modal" data-bs-target="#warehousemasteradd">ADD NEW</button>
      </div>
    </div>
  )
}
