import axios from "axios";
import { endpoint } from "./endpoint";


//Endpoints Destructuring
const { login, BaseUrl , getCounttryMaster, addNewCountry,editCountry,deleteCountry, listWarehouse ,deletewarehouse ,updateWarehouse,addwarehouse , listUsers ,deleteUser ,listScanData , deleteScanData ,getAllCountry,editUser , getAllWarehouse ,addNewUser , paperDimensionsGet , paperDimensionsUpdate } = endpoint;

const LoginApi = async ({ username, password }) => {
  // const dispatch = useDispatch();

 const result =  await axios.post(`${BaseUrl}${login}`, {
  staff_id: username,
    password: password,
  });

  return result
};


const GetCountryMaster = ({ pageNumber })=>{

      
          
          const result = axios.get(`${BaseUrl}${getCounttryMaster}&page=${pageNumber}`,{
            headers:{
              Authorization:`Bearer ${localStorage.getItem('AuthToken')}`
            }
          });


          return result
      
        
}


const AddNewCountry = ({ country_name })=>{
     const result = axios.post(`${BaseUrl}${addNewCountry}`,{
      country_name
     },
     {
      headers:{
        Authorization:`Bearer ${localStorage.getItem('AuthToken')}`
      }
    })

    return result
}


const UpdateCountry = ({ country_name , id })=>{


  const result = axios.put(`${BaseUrl}${editCountry}`,{
   country_name,
   country_id:id
  },
  {
   headers:{
     Authorization:`Bearer ${localStorage.getItem('AuthToken')}`
   }
 })

 return result
}


const DeleteCountry = ({ id })=>{

  const confirmation = window.confirm('Are you sure you want to delete')
  
  let result;
  if (confirmation){
    
    result = axios.delete(`${BaseUrl}${deleteCountry}/${id}`,{
      headers:{
        Authorization:`Bearer ${localStorage.getItem('AuthToken')}`
      }
    });
  }
     

  return result;

}
const ListWarehouse = ({ pageNumber })=>{
  const result = axios.get(`${BaseUrl}${listWarehouse}&page=${pageNumber}`,{
    headers:{
      Authorization:`Bearer ${localStorage.getItem('AuthToken')}`
    }
  });

  return result;
}


const DeleteWarehouse = ({ id })=>{


  const confirmation = window.confirm('Are you sure you want to delete')
   
  let result;
  if (confirmation){
    
  result = axios.delete(`${BaseUrl}${deletewarehouse}/${id}`,{
      headers:{
        Authorization:`Bearer ${localStorage.getItem('AuthToken')}`
      }
    });
  }

  return result;
}

const AddNewWarehouse = ({ name , description , country_id , email })=>{
  email = email.replaceAll(" ", '')
  const result = axios.post(`${BaseUrl}${addwarehouse}`,{
    name,
    description,
    country_id,
    email
   },
   {
    headers:{
      Authorization:`Bearer ${localStorage.getItem('AuthToken')}`
    }
  })
 
  return result
}


const EditWareHouse = ({ id , name , description , country_id ,email  })=>{
  email = email.replaceAll(" ", '')
  const result = axios.put(`${BaseUrl}${updateWarehouse}`,{
    id,
    name,
    description,
    country_id,
    email
   },
   {
    headers:{
      Authorization:`Bearer ${localStorage.getItem('AuthToken')}`
    }
  })
 
  return result 
}

const ListUsers = ({ pageNumber })=>{
     const result =  axios.get(`${BaseUrl}${listUsers}&page=${pageNumber}`,{
      headers:{
        Authorization:`Bearer ${localStorage.getItem('AuthToken')}`
      }
     })

     return result
}


const DeleteUser = async ({ id })=>{

  const confirmation = window.confirm('Are you sure you want to delete')

  let result;

  if (confirmation){

     result = axios.delete(`${BaseUrl}${deleteUser}/${id}`,{
      headers:{
        Authorization:`Bearer ${localStorage.getItem('AuthToken')}`
      }
    });
  }

  return result;


}


const ListScanData = ({pageNumber})=>{
  const result =  axios.get(`${BaseUrl}${listScanData}&page=${pageNumber}`,{
   headers:{
     Authorization:`Bearer ${localStorage.getItem('AuthToken')}`
   }
  })

  return result
}

const DeleteScanData = async ({ id })=>{

  const confirmation = window.confirm('Are you sure you want to delete')

  let result=[]

  if(confirmation){

    
     result = await axios.delete(`${BaseUrl}${deleteScanData}/${id}`,{
      headers:{
        Authorization:`Bearer ${localStorage.getItem('AuthToken')}`
      }
    });


    
  }
  
  return result




}

const GetAllCountry = ()=>{

  const result = axios.get(`${BaseUrl}${getAllCountry}`,{
    headers:{
      Authorization:`Bearer ${localStorage.getItem('AuthToken')}`
    }
  });

  return result;

}


const EditUser = ({ id ,data })=>{
      //editUser
      data.id = data.userid;
      const result = axios.put(`${BaseUrl}${editUser}`,data,{
        headers:{
          Authorization:`Bearer ${localStorage.getItem('AuthToken')}`
        }
      });
    
      return result;
}

const GetAllWareHouse = ()=>{
  const result = axios.get(`${BaseUrl}${getAllWarehouse}`,{
    headers:{
      Authorization:`Bearer ${localStorage.getItem('AuthToken')}`
    }
  });

  return result;
}


const AddNewUser = (newUser)=>{
 
    const result = axios.post(`${BaseUrl}${addNewUser}`,
    newUser,
    {
     headers:{
       Authorization:`Bearer ${localStorage.getItem('AuthToken')}`
     }
   })

   return result

}


const SearchScanData = ({ warehouse_id , productName , pageNumber  })=>{
  const result =  axios.get(`${BaseUrl}${listScanData}&page=${pageNumber}&warehouse_id=${warehouse_id}&keyword=${productName}`,{
    headers:{
      Authorization:`Bearer ${localStorage.getItem('AuthToken')}`
    }
   })

   return result
}

const GetPaperDimension =()=>{

  const result =  axios.get(`${BaseUrl}${paperDimensionsGet}`,{
    headers:{
      Authorization:`Bearer ${localStorage.getItem('AuthToken')}`
    }
   })

   return result



}

const UpdatepaperDimension =(data)=>{


    const result =  axios.post(`${BaseUrl}${paperDimensionsUpdate}`,data,{
      headers:{
        Authorization:`Bearer ${localStorage.getItem('AuthToken')}`
      }
     })
  
     return result

}














export default {LoginApi , GetCountryMaster , AddNewCountry , UpdateCountry, DeleteCountry,ListWarehouse , DeleteWarehouse,AddNewWarehouse , EditWareHouse , ListUsers ,DeleteUser , ListScanData ,DeleteScanData,GetAllCountry,GetAllWareHouse,AddNewUser,EditUser, SearchScanData ,GetPaperDimension, UpdatepaperDimension  };

