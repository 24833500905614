import {config} from './config'

export const endpoint = {
   
    
    BaseUrl:"http://20.228.180.238:5000", // linux server
    login:"/admin/login",
    getCounttryMaster:`/admin/country/list?limit=${config.pageLimit}`,
    addNewCountry:"/admin/country/create",
    editCountry:"/admin/country/update",
    deleteCountry:"/admin/country/delete",
    listWarehouse:`/admin/warehouse/list?limit=${config.pageLimit}`,
    updateWarehouse:"/admin/warehouse/update",
    addwarehouse:"/admin/warehouse/create",
    deletewarehouse:"/admin/warehouse/delete",
    listUsers:`/admin/users/list?limit=${config.pageLimit}`,
    deleteUser:"/admin/users/delete",
    listScanData:`/admin/scandata/list?limit=${config.pageLimit}`,
    deleteScanData:"/admin/scandata/delete",
    getAllCountry:"/admin/all-country/list",
    addNewUser:"/admin/users/create",
    editUser:"/admin/users/update",
    getAllWarehouse:"/admin/all-warehouse/list",
    scanDataExcel:"/admin/scandata/export",
    userDataExcel:"/admin/users/export",
    wareHouseDataExcel:"/admin/warehouse/export",
    countryDataExcel:"/admin/country/export",
    paperDimensionsGet:"/admin/paper_dimension/get",
    paperDimensionsUpdate:"/admin/paper_dimension/update"
   
};