import React from 'react';
import PropTypes from 'prop-types'
import logo from "../image/Top.png"
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAuth } from '../store/authSlice';


export default function Navbar(props) {

    const dispatch = useDispatch();


    const LogOutUser = ()=>{
           dispatch(setAuth({user:null, access_token:null }))
    }


    return (
        <>
            <nav className="navbar navbar-expand-lg my-bg">
                <div className="container-fluid">
                    <a className="navbar-brand" to="/"><img src={logo} alt="logo"/></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                        <ul className="navbar-nav mb-2 mb-lg-0">
                            <li className="nav-item">
                                <NavLink className="nav-link" aria-current="page" to="/scandata">{props.scanned}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/usermaster">{props.user}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/countrymaster">{props.country}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/warehousemaster">{props.warehouse}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/paperDimension">Paper Dimension</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink onClick={LogOutUser}  className="nav-link" to="/">{props.logout}</NavLink>
                            </li>
                            
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}


Navbar.prototype = {
    scanned: PropTypes.string,
    user: PropTypes.string,
    country: PropTypes.string,
    warehouse: PropTypes.string,
    logout: PropTypes.string
}
