import React from 'react'
import delicon from "../image/del.png"
import Request from '../API/Requests';
import { useDispatch } from 'react-redux';
import { setAuth } from '../store/authSlice';
import { Navigate } from 'react-router-dom';




const DateFormatter = (created_at)=>{
  const date = new Date(created_at);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  if(created_at == null){
    return null
  }
  else{
  return `${year}-${month}-${day}`;
  }
}

const TableRow = ({ wareHouseData,userid , first_name , staff_id, last_name, password, country_name, warehouse,  role, updated_at, updated_by, created_at, created_by, id ,seteditUserData , country_id , warehouse_id } ) => {

  const dispatch = useDispatch();

const onClickDelete = () => {
       Request.DeleteUser({id}).then(() => {
        window.location.reload(false);
       }).catch((error )=> {
        console.log(error)
        if(error.response.status === 401){
          dispatch(setAuth({user:null, access_token:null }))
  
        }
        else{
          alert("Something went wrong")
        }
        console.log(error)
       })
}


const onChangeCountry_Warehouse= ({ id ,wareHouseData })=>{

      

  let country_Based_warehouse_ = [];
  
  wareHouseData.forEach((ele,i)=>{
    
     if(ele.country_id == id){
        
         country_Based_warehouse_.push(ele)
     }
  })

 
  
  return country_Based_warehouse_
  
}


const onClikEditButton = () => {
  let wareHouseArray = onChangeCountry_Warehouse({ id:country_id , wareHouseData:wareHouseData })
  
     seteditUserData({
      wareHouseArray,
      first_name,
        last_name,
        staff_id,
        country_id,
        warehouse_id,
        password,
        role,
        userid,
        confirm_password:password
     })
}


  return (
    <tr>
        <th scope="row">
        <button  onClick={onClickDelete} className="di"><img className="del" src={delicon} alt="del"/></button>
        <button onClick={onClikEditButton} className="di dis" data-bs-toggle="modal" data-bs-target="#usermasteredit"> Edit</button>                
        </th>
        <td><span className="stn">{staff_id}</span></td>
        <td>{ last_name }, {first_name}</td>
        <td>{country_name}</td>
        <td>{warehouse}</td>
        <td>{password}</td>
        <td>{role}</td>
        <td>{updated_by}</td>
        <td>{DateFormatter(updated_at)}</td>
        <td>{created_by}</td>
        <td>{DateFormatter(created_at)}</td>
    </tr>
  )
}

export default TableRow
