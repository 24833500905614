import React from 'react';
import delicon from '../image/del.png';
import { setAuth } from '../store/authSlice';
import { useDispatch } from 'react-redux';
import Request from '../API/Requests';


export default function WarehouseTableRow({ list, seteditWareHouse
 ,country_id
}) {

  const { DeleteWarehouse } = Request
  const dispatch = useDispatch();

  


  const onClickEditWarehouse = ()=>{
      
    seteditWareHouse((prev)=>{
      return {
        id:list.id,
    name:list.name,
    description:list.description,
    country_id:country_id,
    country_name:list.country_name,
    email:list.email,
      }
    })

    

  }

  const onClickDelete = async ()=>{
    try {
      const res = await DeleteWarehouse({id : list.id })
      window.location.reload();
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch(setAuth({user:null, access_token:null }))

      }
      console.log(error)
    }
  }




  const DateFormatter = (created_at)=>{
    const date = new Date(created_at);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    if(created_at == null){
      return null
    }
    else{
    return `${year}-${month}-${day}`;
    }
  }
     


  return (
    <tr>
        <th scope="row">
        <button onClick={onClickDelete}  className="di"><img className="del" src={delicon} alt="del"/></button>
        <button onClick={onClickEditWarehouse} className="di dis" data-bs-toggle="modal" data-bs-target="#warehousemasteredit"> Edit</button>        
        </th>
        <td><span className="stn">{list.name}</span></td>
        <td>{list.description
        }</td>
        <td>{list.country_name}</td>
        <td>{
          list.email
        }</td>
        <td>{list.updated_by}</td>
        <td>{DateFormatter(list.updated_at)}</td>
        <td>{list.created_by}</td>
        <td>{ DateFormatter(list.created_at) }</td>
    </tr>
  )
}
