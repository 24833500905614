import './App.css';
import Scandata from "./pages/Scandata";
import Login from './pages/Login';
import Usermaster from './pages/Usermaster';
import Countrymaster from './pages/Countrymaster';
import Warehousemaster from './pages/Warehousemaster';
import { Navigate } from 'react-router-dom';


import {  
  Route,
  Routes
} from "react-router-dom";

import { useSelector } from 'react-redux';
import Loader from './components/Loader';
import RealTime from './pages/RealTime';


const PrivtaeRoute = ({children}) => {
  const {isAuth , user} = useSelector((state)=> state.auth)
if (isAuth) {
    return  children;
} else {
    return <Navigate to="/"/>
}
}


const AlreadyLoginReDirect = ({children})=>{
  const {isAuth , user} = useSelector((state)=> state.auth)
  if(isAuth){
    return <Navigate to='/scandata' />
  }
  else{
    return children
  }
}



function App() {

  const {  Loading } = useSelector((state)=>state.auth)


  
  return (
    Loading?<Loader/>:
    <>
       <Routes>            
          <Route path="/" element={ <AlreadyLoginReDirect> <Login/> </AlreadyLoginReDirect> }/>                    
          <Route path="/scandata" element={<PrivtaeRoute><Scandata/></PrivtaeRoute>}/>         
          <Route path="/usermaster" element={ <PrivtaeRoute><Usermaster/></PrivtaeRoute> }/>         
          <Route path="/countrymaster" element={<PrivtaeRoute><Countrymaster/></PrivtaeRoute>}/>         
          <Route path="/warehousemaster" element={ <PrivtaeRoute><Warehousemaster/></PrivtaeRoute> }/>         
          <Route path="/paperDimension" element={ <PrivtaeRoute><RealTime/></PrivtaeRoute> }/>         
  </Routes>    
    </>
  );
}

export default App;





