import React from 'react';
import delicon from "../image/del.png";
import Modal from './Modal';
import Request from '../API/Requests';
import { useDispatch } from 'react-redux';
import { setAuth } from '../store/authSlice';

export default function CountryTableRow({country_name ,updated_by , updated_at,created_at,created_by ,seteditCountry,id }) {

   const dispatch = useDispatch();




 const onClickDelete = async ()=>{

     
         try {
            await Request.DeleteCountry({ id})
            window.location.reload(false);
         } catch (error) {
          if(error.response.status === 401){
            dispatch(setAuth({user:null, access_token:null }))
    
          }
          else{
            alert("Something went wrong")
          }
          console.log(error)
         }
 }

  const onClickEdit = ()=>{
      
      seteditCountry({
        country_name:country_name,
        id:id
      })
  }

  

const DateFormatter = (created_at)=>{
  const date = new Date(created_at);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  if(created_at == null){
    return null
  }
  else{
  return `${year}-${month}-${day}`;
  }
}
  
  return (
    <>
    <tr>
        <th scope="row">
        <button onClick={onClickDelete} className="di"><img className="del" src={delicon} alt="del"/></button>
        <button onClick={onClickEdit} className="di dis"  data-bs-toggle="modal" data-bs-target="#countrymaster">Edit</button>
        
        </th>
        <td><span className="stn">{country_name}</span></td>
        <td>{updated_by}</td>
        <td>{DateFormatter(updated_at)}</td>
        <td>{created_by}</td>
        <td>{DateFormatter(created_at)}</td>
    </tr>
    
    </>
  )
}
