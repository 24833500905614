import React, { useState ,useEffect } from 'react'
import Heading from '../components/Heading';
import ExelAdd from '../components/ExelAdd';
import CountryTableHead from '../components/CountryTableHead';
import CountryTableRow from '../components/CountryTableRow';
import TotalEntry from '../components/TotalEntry';
import Modal from '../components/Modal';
import Navbar from "../components/Navbar";
import Request from '../API/Requests'
import { setAuth ,setLoading } from '../store/authSlice';
import { useDispatch } from 'react-redux';
import Loader from '../components/Loader';
import Pagination from '../components/Pagination';




export default function Countrymaster() {

  const dispatch = useDispatch();
  // dispatch(setLoading({ IsLoading: true }));
  const [currentPage, setcurrentPage] = useState(1)
  const [max_page, setmax_page] = useState(0);
  const [totalRecords, settotalRecords] = useState(0)
  


  const [countryMasterData, setcountryMasterData] = useState([])
   const [editCountry, seteditCountry] = useState({
    country_name:'',
    id:''
   })

   const [IsLoading, setIsLoading] = useState(true)




   const onPageChange = async ({ pageNumber })=>{
    try {
        
      const result = await Request.GetCountryMaster({pageNumber})
      setmax_page(result.data.max_page)
      
      setcountryMasterData(result.data.data)
      setIsLoading(false)
      
    } catch (error) {
      if(error.response.status === 401){
        dispatch(setAuth({user:null, access_token:null }))
        // dispatch(setLoading({ IsLoading: false }));
      }else{
      

      console.log(error)
      alert("Something went wrong")
      }
    }
   }

  

   


  useEffect( () => {


    const getCountry = async () =>{
      try {
        
        const result = await Request.GetCountryMaster({pageNumber:1})
        setcountryMasterData(result.data.data)
        setIsLoading(false)
        settotalRecords(result.data.total_record) 
        
      } catch (error) {
        if(error.response.status === 401){
          dispatch(setAuth({user:null, access_token:null }))
          // dispatch(setLoading({ IsLoading: false }));
        }else{
      

          console.log(error)
          alert("Something went wrong")
          }
      }
      // dispatch(setLoading({ IsLoading: false }));
        
      
    }

    
    
    getCountry();
    
        
    // dispatch(setLoading({ IsLoading: true }));
    // Request.GetCountryMaster().then((result)=>{
         
      
    //      setcountryMasterData(result.data.data)
         
    // }).catch((error) => {
    //   if(error.response.status === 401){
    //     dispatch(setAuth({user:null, access_token:null }))

    //   }
      
    //   console.log(error)
    // })
    
    // dispatch(setLoading({ IsLoading: false }));
      
  }, [])
  


    // const countryMasterData = [1,2,3,4]
    
  return (
    IsLoading?<Loader/>:
    
    <>
   
    <Navbar 
        scanned="Scanned data" 
        user="User master" 
        country="Country master" 
        warehouse="Warehouse master" 
        logout="Log Out"
      />
    <div className="container  pt-50">
        <div className="row">
            <div className="col-md-6 col-sm-6">
              <Heading heading={"Country master"}/>
            </div>
            <div className="col-md-6 col-sm-6">
                    <ExelAdd triggeringClass={'addcountry'} exportPage={"country"} />              
            </div>
            <div className="col-md-12">
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                          <CountryTableHead/>
                        </thead>
                        <tbody>
                            {
                                countryMasterData.map((ele,i)=>{
                                return <CountryTableRow  country_name={ele.country_name} created_at={ele.created_at} 
                                created_by={ele.created_by}
                                updated_at={ele.updated_at}
                                updated_by={ele.updated_by}
                                id={ele.id}
                                seteditCountry={seteditCountry}
                                key={i}/>
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="paginationcont">
                    <TotalEntry total={totalRecords}/>
                    <Pagination currentPage={currentPage} setcurrentPage={setcurrentPage} onPageChange={onPageChange} max_page={max_page}/>
                </div>
                
            </div>
        </div>
    </div>
    <Modal editCountry={editCountry} seteditCountry={seteditCountry} />
    </>
  )
}
