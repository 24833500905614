import React , { useState , useEffect,useRef } from 'react'
import Request from '../API/Requests'
import { setAuth } from '../store/authSlice'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'




export default function UserModalAdd() {

    const {user} = useSelector((state)=> state.auth)


    const [countryData, setcountryData] = useState([])
    const [wareHouseData, setwareHouseData] = useState([])
    const dispatch = useDispatch();
    const [first_name, setfirst_name] = useState('')
    const [last_name, setlast_name] = useState('')
    const [staffId, setstaffId] = useState('')
    const [countryId, setcountryId] = useState(null)
    const [warehouse_id, setwarehouse_id] = useState(null)
    const [password, setpassword] = useState('')
    const [role, setrole] = useState('');
    const [confirm_password, setconfirm_password] = useState('')
    // This state will have warehouse list based on Country
    const [country_warehouse, setcountry_warehouse] = useState([])
    

    useEffect(() => {

      
      
        Request.GetAllCountry().then((res)=>{
             setcountryData(res.data.data)
             let temp_country_id;
             if(user.role === 'admin'){
             setcountryId(user.country_id)
             temp_country_id = user.country_id;
             }
             else{

                 setcountryId(res.data.data[0].id)
                 temp_country_id = res.data.data[0].id
             }

                
             //Get Ware House Data.


             Request.GetAllWareHouse().then((result)=>{
                setwareHouseData(result.data.data)
                setwarehouse_id(result.data.data[0].id)
                 
                onChangeCountry_Warehouse({id:temp_country_id , wareHouseData:result.data.data})


            }).catch((error) => {
                if(error.response.status === 401){
                    dispatch(setAuth({user:null, access_token:null }))
                    // dispatch(setLoading({ IsLoading: false }));
                  }else{
                
          
                    console.log(error)
                    alert("Something went wrong")
                    }
            })








        }).catch((error) => {
            
            if(error.response.status === 401){
                dispatch(setAuth({user:null, access_token:null }))
                // dispatch(setLoading({ IsLoading: false }));
              }else{
            
      
                console.log(error)
                alert("Something went wrong")
                }
        })

    
    
     
    }, [])


    const onChangeCountry_Warehouse= ({ id ,wareHouseData })=>{

      

         let country_Based_warehouse_ = [];
         
         wareHouseData.forEach((ele,i)=>{
           
            if(ele.country_id == id){
               
                country_Based_warehouse_.push(ele)
            }
         })

        
         
         setcountry_warehouse(country_Based_warehouse_);
         
    }


    const onChangestaffId = (e)=>{
          setstaffId(e.target.value);
    }

    const onChangeFirstName = (e)=>{
        setfirst_name(e.target.value);
    }
    
    const onChangeLastName= (e)=>{
        setlast_name(e.target.value);
    }
   
    const onChangeCountryId = (e)=>{
        onChangeCountry_Warehouse({ id:e.target.value , wareHouseData:wareHouseData })
        setcountryId(e.target.value);
    }

    const onChangeWareHouseId = (e)=>{
        setwarehouse_id(e.target.value);
    }

    const onChangePassword = (e)=>{
        setpassword(e.target.value);
    }
     
    const onChangeConfirmPassword = (e)=>{
        setconfirm_password(e.target.value);
    }


    const onChangeRole = (e)=>{
        setrole(e.target.value);
    }

    const onClickSaveChanges = (e)=>{


        if(confirm_password !== password){
            alert("Passwords do not match with Confirm Password")
            return
        }

        Request.AddNewUser({
        first_name:first_name,
        last_name:last_name,
        staff_id:staffId,
        country_id:countryId,
        warehouse_id:warehouse_id,
        password:password,
        role:role,
        confirm_password:confirm_password,
            }).then((result)=>{
            window.location.reload(false);
        }).catch((error)=>{
            if(error.response.status === 401){
                dispatch(setAuth({user:null, access_token:null }))
                // dispatch(setLoading({ IsLoading: false }));
              }else{
            
      
                console.log(error)
                alert(error.response.data.error)
                }
        })
    }


    


  return (
    <div className="modal fade" id="usermasteradd" tabIndex="-1" aria-labelledby="usermaster" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">Add New</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label htmlFor="staffid" className="form-label">Staff ID</label>
                                    <input value={staffId} onChange={onChangestaffId} type="text" className="form-control" id="staffid" placeholder=""/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label htmlFor="firstandlastname" className="form-label"> First name</label>
                                    <input value={first_name} onChange={onChangeFirstName} type="text" className="form-control" id="firstandlastname" placeholder=""/>
                                </div>
                            </div>
                            <div className="col-md-6">
                            <div className="mb-2"> 
                                <label htmlFor="firstandlastname" className="form-label"> Last Name</label>
                                <input value={last_name} onChange={onChangeLastName} type="text" className="form-control" id="firstandlastname" placeholder=""/>
                            </div>
                        </div>
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label htmlFor="sountry" className="form-label">Country</label>
                                  {(user.role === 'admin')?(  <select disabled value={user.country_id}   className="form-select" aria-label="Default select example">
                                  {
                                      countryData.map((ele,i)=>{
                                          return <option key={i} value={ele.id}>{ele.country_name}</option>
                                      })
                                  }
                                  
                              </select>):(  <select  onChange={onChangeCountryId} className="form-select" aria-label="Default select example">
                                        {
                                            countryData.map((ele,i)=>{
                                                return <option key={i} value={ele.id}>{ele.country_name}</option>
                                            })
                                        }
                                        
                                    </select>)}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label htmlFor="warehouse" className="form-label">Warehouse</label>

                                    <select  onChange={onChangeWareHouseId} className="form-select" aria-label="Default select example">
                                    {
                                        country_warehouse.map((ele,i)=>{
                                            return <option key={i} value={ele.id}>{ele.name}</option>
                                        })
                                    }
                                    </select>
                                </div>
                            </div>
                           
                            <div className="col-md-6">
                            <div className="mb-2">
                                <label htmlFor="password" className="form-label">Password</label>
                                <input value={ password } onChange={onChangePassword} type="text" className="form-control" id="password" placeholder=""/>
                            </div>
                        </div>
                        <div className="col-md-6">
                        <div className="mb-2">
                            <label htmlFor="password" className="form-label">Confirm Password</label>
                            <input value={ confirm_password } onChange={onChangeConfirmPassword} type="text" className="form-control" id="password" placeholder=""/>
                        </div>
                    </div>
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label htmlFor="role" className="form-label">Role</label>
                                    <select value={role} onChange={onChangeRole} className="form-select" aria-label="Default select example">
                                    <option value={""} ></option>
                                  <option value={"admin"} >Admin</option>
                                  <option value={"staff"} >Staff</option>
                            </select>
                                   
                                </div>
                            </div>                            
                        </div>
                    </div>
                    <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button onClick={onClickSaveChanges} type="button" className="btn btn-primary">Save changes</button>
                    </div>
                </div>
            </div>
        </div>
  )
}
