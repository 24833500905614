import React,{useEffect , useState} from 'react'
import { useDispatch } from 'react-redux'
import { setAuth } from '../store/authSlice'
import Request from '../API/Requests';
import { ValidateEmail } from '../Validators/Email';
import { useSelector } from 'react-redux';

export default function Warehousemodal({ editWareHouse ,seteditWareHouse }) {

    const dispatch = useDispatch();
    const {user } = useSelector((state)=> state.auth)


    const [countryMasterData, setcountryMasterData] = useState([])
   
   
    const [name, setname] = useState('');
    const [description, setdescription] = useState('');
    const [country_id, setcountry_id] = useState(null);
    const [email, setemail] = useState('')



    // onChange When the Add New Modal is triggered
    const onChangeName = (e)=>{
        setname(e.target.value)
    }

    const onChangeDescription = (e)=>{
        setdescription(e.target.value)
    }

    const onChangeMail = (e)=>{
        setemail(e.target.value)
    }

    const onChangeCountry_ID = (e)=>{
        setcountry_id(e.target.value)
    }


 
 
   useEffect(() => {
 
     Request.GetAllCountry().then((result)=>{
          
         
          setcountryMasterData(result.data.data)
          if(user.role === 'admin'){
          setcountry_id(user.country_id)

          }
          else{

              setcountry_id(result.data.data[0].id)
          }
          
     }).catch((error) => {
       if(error.response.status === 401){
         dispatch(setAuth({user:null, access_token:null }))
 
       }
       console.log(error)
     })
       
   }, [])

   const onClickAddNewWareHouse = async () =>{
    if(ValidateEmail({EmailList:email})){

    

    try {

        await Request.AddNewWarehouse({
            name,
            description,
            country_id,
            email
           })
           window.location.reload(false)
        
    } catch (error) {
        if(error.response.status === 401){
            dispatch(setAuth({user:null, access_token:null }))
    
          }
          console.log(error.response.data.error)
          alert(error.response.data.error)
        
    }

}
else{
    alert("Please Provide Email in Proper Format ")
}

       
       
   }



   // onChange when edit modal is triggered

   const onClickEditWareHouse = async () =>{
        
    console.log(editWareHouse)

    //ValidateEmail({EmailList:email})
    if(!ValidateEmail({EmailList:editWareHouse.email})){
        alert("Please Provide a valid Email")
    }else{
        
    try {
        await Request.EditWareHouse(editWareHouse);
        window.location.reload(false);
    } catch (error) {
        if(error.response.status === 401){
            dispatch(setAuth({user:null, access_token:null }))
    
          }
          console.log(error)
          alert(error.response.data.error)
    }
}
       
   }

   const onChangeEditName = (e)=>{
        seteditWareHouse((prev)=>{
            return {
                id: prev.id,
                name:e.target.value,
                description:prev.description,
                country_id:prev.country_id,
                email:prev.email,
              
            }
        })
   }

   const onChangeEditDescription = (e)=>{
    seteditWareHouse((prev)=>{
        return {
            id: prev.id,
            description:e.target.value,
            country_id:prev.country_id,
            email:prev.email,
            name:prev.name
        }
    })
   }

   const onChangeEditContry = (e)=>{
    seteditWareHouse((prev)=>{
        return {
            id: prev.id,
            description:prev.description,
            country_id:e.target.value,
            email:prev.email,
            name:prev.name
        }
    })
   }

   const onChangeEditEmail = (e)=>{
    seteditWareHouse((prev)=>{
        return {
            id: prev.id,
            description:prev.description,
            country_id:prev.country_id,
            email:e.target.value,
            name:prev.name
        }
    })
   }
    

  return (
    <>
      {/* Edit WareHouse*/}
        <div className="modal fade" id="warehousemasteredit" tabIndex="-1" aria-labelledby="warehousemasteredit" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable">
            <div className="modal-content">
                <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">Edit</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label htmlFor="warehouse" className="form-label">Warehouse name</label>
                                <input onChange={onChangeEditName} value={editWareHouse.name} type="text" className="form-control" id="warehouse" placeholder=""/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label htmlFor="sountry" className="form-label">Country</label>
                                {(user.role === 'admin')?(  <select disabled value={user.country_id}   className="form-select" aria-label="Default select example">
                            {
                                countryMasterData.map((ele,i)=>{
                                    return <option key={i} value={ele.id}>{ele.country_name}</option>
                                })
                            }
                            
                        </select>):(  <select  onChange={onChangeCountry_ID} className="form-select" aria-label="Default select example">
                                  {
                                    countryMasterData.map((ele,i)=>{
                                          return <option key={i} value={ele.id}>{ele.country_name}</option>
                                      })
                                  }
                                  
                              </select>)}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-2">
                                <label htmlFor="description" className="col-form-label">Description</label>
                                <textarea onChange={onChangeEditDescription} value={editWareHouse.description} className="form-control" id="Description"></textarea>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-2">
                                <label htmlFor="warehouseemail" className="form-label">Email</label>
                                <textarea onChange={onChangeEditEmail} value={editWareHouse.email} className="form-control" id="warehouseemail"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button onClick={onClickEditWareHouse} type="button" className="btn btn-primary">Save changes</button>
                </div>
            </div>
            </div>
        </div>
        {/* end */}
        
        {/* Add New Warehouse master*/}
        <div className="modal fade" id="warehousemasteradd" tabIndex="-1" aria-labelledby="warehousemasteradd" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable">
            <div className="modal-content">
                <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">ADD NEW</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label htmlFor="warehouse" className="form-label">Warehouse name</label>
                                <input type="text" onChange={onChangeName} className="form-control" id="warehouse" placeholder=""/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-2">
                            <label htmlFor="sountry" className="form-label">Country</label>

                            {(user.role === 'admin')?(  <select  disabled value={user.country_id}   className="form-select" aria-label="Default select example">
                            {
                                countryMasterData.map((ele,i)=>{
                                    return <option key={i} value={ele.id}>{ele.country_name}</option>
                                })
                            }
                            
                        </select>):(  <select  onChange={onChangeCountry_ID} className="form-select" aria-label="Default select example">
                                  {
                                    countryMasterData.map((ele,i)=>{
                                          return <option key={i} value={ele.id}>{ele.country_name}</option>
                                      })
                                  }
                                  
                              </select>)}




                            
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-2">
                                <label htmlFor="description" className="col-form-label">Description</label>
                                <textarea onChange={onChangeDescription} className="form-control" id="Description"></textarea>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-2">
                                <label htmlFor="warehouseemail" className="form-label">Email</label>
                                <textarea onChange={onChangeMail} className="form-control" id="warehouseemail"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button onClick={onClickAddNewWareHouse} type="button" className="btn btn-primary">Save changes</button>
                </div>
            </div>
            </div>
        </div>
        {/* end */}
    </>
  )
}
