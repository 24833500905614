import React , {useEffect , useState } from 'react';
import { useDispatch ,useSelector } from 'react-redux';
import { setAuth } from '../store/authSlice';
import Request from '../API/Requests';
import ScanDataTAbleHead from '../components/ScanDataTAbleHead';
import Pagination from '../components/Pagination';
import TotalEntry from '../components/TotalEntry';
import Navbar from '../components/Navbar';
import Heading from '../components/Heading';
import Loader from '../components/Loader';
import { endpoint } from '../API/endpoint'

export default function Scandata() {
  // const ExcelURL = `${endpoint.BaseUrl}${endpoint.scanDataExcel}`

  const { user } = useSelector((state)=> state.auth)
     
    const dispatch = useDispatch();
    const [scanData, setscanData] = useState([]);
    const [IsLoading, setIsLoading] = useState(true)
    const [currentPage, setcurrentPage] = useState(1)
    const [max_page, setmax_page] = useState(0);
    const [warehouseData, setwarehouseData] = useState([])
    const [selectedWareHouse, setselectedWareHouse] = useState(0)
    const [totalRecords, settotalRecords] = useState(0)
    const [selectedProduct, setselectedProduct] = useState('')
   




    useEffect(() => {
       
      Request.ListScanData({ pageNumber:1 }).then((result)=>{
        // setmax_page(result.data.max_page)
        setmax_page(result.data.max_page)
        setscanData(result.data.data)
        settotalRecords(result.data.total_record)
        setIsLoading(false)
      }).catch((error) => {
        if(error.response.status === 401){
          dispatch(setAuth({user:null, access_token:null }))
  
        }else{
      

          console.log(error)
          alert("Something went wrong")
          }
      })

      Request.GetAllWareHouse().then((result)=>{
         setwarehouseData(result.data.data)
         
         
      }).catch((error) => {
        if(error.response.status === 401){
          dispatch(setAuth({user:null, access_token:null }))
  
        }else{
      

          console.log(error)
          alert("Something went wrong")
          }
      })
    
    
    }, [])


    const [ExcelURL, setExcelURL] = useState(`${endpoint.BaseUrl}${endpoint.scanDataExcel}?country_id=${user.role === 'super_admin'?0:user.country_id}`)


    const exportURLOnChangeSearch = ({ warehouse_id, product_name })=>{
       //&warehouse_id=${warehouse_id}&keyword=${productName}

       setExcelURL(`${endpoint.BaseUrl}${endpoint.scanDataExcel}?country_id=${user.role === 'super_admin'?0:user.country_id}&warehouse_id=${warehouse_id}&keyword=${product_name}`)
    }


    const onPageChange = ( {pageNumber} ) => {

      console.log(selectedWareHouse,"SelectWarehouse")
         
      Request.SearchScanData({pageNumber ,warehouse_id:selectedWareHouse , productName:selectedProduct}).then((result)=>{
        
        
        setscanData(result.data.data)
        setIsLoading(false)
        settotalRecords(result.data.total_record)
      }).catch((error) => {
        if(error.response.status === 401){
          dispatch(setAuth({user:null, access_token:null }))
  
        }else{
      

          console.log(error)
          alert("Something went wrong")
          }
      })

    }

    const onChangeWareHouse = (e)=>{

         
      
     
      setselectedWareHouse(e.target.value)

      exportURLOnChangeSearch({ warehouse_id:e.target.value , product_name:selectedProduct })
      Request.SearchScanData({ warehouse_id:e.target.value , productName:selectedProduct , pageNumber:1  }).then((result)=>{

         console.log(result)
          setscanData(result.data.data);
          setmax_page(result.data.max_page)
          settotalRecords(result.data.total_record)
      }).catch((error)=>{
        if(error.response.status === 401){
          dispatch(setAuth({user:null, access_token:null }))
  
        }else{
      

          console.log(error)
          alert("Something went wrong")
          }
      })
     
    }

    const onChangeSeletedProduct = (e)=>{
      setselectedProduct(e.target.value)

      
     
    }


    const onClickSearchButton = ()=>{
      exportURLOnChangeSearch({ warehouse_id:selectedWareHouse , product_name:selectedProduct })
        Request.SearchScanData({ warehouse_id:selectedWareHouse , productName:selectedProduct , pageNumber:1  }).then((result)=>{
            setscanData(result.data.data);
            setmax_page(result.data.max_page)
            settotalRecords(result.data.total_record)
        }).catch((error)=>{
          if(error.response.status === 401){
            dispatch(setAuth({user:null, access_token:null }))
    
          }else{
        
  
            console.log(error)
            alert("Something went wrong")
            }
        })
    }


  const GetAllWareHouse = ()=>{
       
    setselectedProduct([])

    setselectedWareHouse([])

    Request.ListScanData({ pageNumber:1 }).then((result)=>{
      // setmax_page(result.data.max_page)
      setmax_page(result.data.max_page)
      setscanData(result.data.data)
      settotalRecords(result.data.total_record)
      setIsLoading(false)
    }).catch((error) => {
      if(error.response.status === 401){
        dispatch(setAuth({user:null, access_token:null }))

      }else{
    

        console.log(error)
        alert("Something went wrong")
        }
    })

    Request.GetAllWareHouse().then((result)=>{
       setwarehouseData(result.data.data)
       setselectedWareHouse(result.data.data[0].id)
       
    }).catch((error) => {
      if(error.response.status === 401){
        dispatch(setAuth({user:null, access_token:null }))

      }else{
    

        console.log(error)
        alert("Something went wrong")
        }
    })
           
  } 
    


  return (
    IsLoading?<Loader/>:
    <>
    <Navbar 
        scanned="Scanned data" 
        user="User master" 
        country="Country master" 
        warehouse="Warehouse master" 
        logout="Log Out"
      />
      <div className="container pt-50">
        <div className="row">
            <div className="col-md-12">
            <Heading  heading={"Scanned data"}/>

            <div className="row">
            
            <div className="form-group col-md-3">
            <label>Filtered by warehouse</label>
            <select onChange={onChangeWareHouse} className="form-select" aria-label="Default select example">
            <option key={0} value={0} >All</option>
            {
              warehouseData.map((ele,i)=>{
               
                return <option key={i+1} value={ele.id}>{ele.name}</option>
              })

             
            }
        
          
          </select>
          {/* <button onClick={GetAllWareHouse} style={ 
            {
              marginTop: '5px',
              marginBottom:'5px'
            }
          } className="btn btn-outline-secondary" type="button" id="button-addon2">All</button> */}
         
            </div>
            <div className="form-group col-md-3">
            
            <label>Search product name</label>
            <div className="input-group mb-4 sws">
                                <input value={selectedProduct} onChange={onChangeSeletedProduct} type="text" className="form-control" placeholder="" aria-label="" aria-describedby="button-addon2"/>
                                <button onClick={onClickSearchButton} className="btn btn-outline-secondary" type="button" id="button-addon2"><i className="fa fa-search" aria-hidden="true"></i></button>
                              </div>
            </div>
            <div className="col-md-6">
            <button className="excel float-end mt-4"><a href={ExcelURL} > EXCEL </a></button>
            </div>
            </div>
                <div className="datacontainwrap">
                    <div className="datainner">
                        <div className="datasheet">
                          <div className="tabledata">
                            <div className="table-responsive">
                              <table className="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th scope="col"></th>
                                      <th scope="col">Country</th>
                                      <th scope="col">Warehouse</th>
                                      <th scope="col">Type</th>
                                      <th scope="col">Product Id</th>
                                      <th scope="col">Client Id</th>
                                      <th scope="col">Width(cm)</th>
                                      <th scope="col">Depth (cm)</th>
                                      <th scope="col">Height(cm)</th>
                                      <th scope="col">Weight(g)</th>
                                      <th scope="col">Qty/pack</th>
                                      <th scope="col">Create by</th>
                                      <th scope="col">Create date</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      scanData.map((ele,i)=>{
                                        return <ScanDataTAbleHead country_name={ele.country_name} warehouse={ele.warehouse} type={ele.type} product_name={ele.product_name} width={ele.width}
                                        depth={ele.depth} height={ele.height} weight={ele.weight} qty={ele.qty} created_by={ele.created_by} client_id={ele.client_id}
                                        created_at={ele.created_at} id={ele.id} product_id={ele.product_id}   key={i}/>
                                      })
                                    }                                    
                                    
                                  </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="paginationcont">
                            <TotalEntry total={totalRecords} />
                            <Pagination currentPage={currentPage} setcurrentPage={setcurrentPage} onPageChange={onPageChange} max_page={max_page} />
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>      
    </>
  )
}
