import React from 'react'

const CountryTableHead = () => {
  return (
    <tr>
        <th scope="col"></th>
        <th scope="col">Country name</th>
        <th scope="col">Updated by</th>
        <th scope="col">Update date</th>
        <th scope="col">Created by</th>
        <th scope="col">Create date</th>
    </tr>
  )
}

export default CountryTableHead
