import React , { useEffect , useState } from 'react'
import Navbar from "../components/Navbar";
import Request from '../API/Requests'
import { useDispatch ,useSelector } from 'react-redux';
import { setAuth } from '../store/authSlice';
import Loader from '../components/Loader';


export default function RealTime() {

    const dispatch = useDispatch();
    const [paperDimension, setpaperDimension] = useState('')
    const [IsLoading, setIsLoading] = useState(true)
    


    useEffect(()=>{

        setIsLoading(true)
        
        Request.GetPaperDimension().then((result)=>{
              setpaperDimension(result.data.data)

              setIsLoading(false)
             
              console.log(result.data.data);
           
        }).catch((error)=>{
            setIsLoading(false)
            if(error.response.status === 401){
                dispatch(setAuth({user:null, access_token:null }))
        
              }else{
            
      
                console.log(error)
                alert("Something went wrong")
                }
        })




    },[])


    const onChangeSmallSizeWidth = (e)=>{
          
        let currentData = paperDimension;

        currentData.small_marker_width = parseInt(e.target.value);

        console.log(currentData)


        setpaperDimension(currentData)

    }

    const onChangeSmallSizeDepth= (e)=>{
          
        let currentData = paperDimension;

        currentData.small_marker_depth = parseInt(e.target.value);

        console.log(currentData)


        setpaperDimension(currentData)

    }


    const onChangeSmallSizeHeight= (e)=>{
          
        let currentData = paperDimension;

        currentData.small_marker_height = parseInt(e.target.value);

        console.log(currentData)


        setpaperDimension(currentData)

    }

    const onChangeLargeSizeDepth= (e)=>{
          
        let currentData = paperDimension;

        currentData.large_marker_depth = parseInt(e.target.value);

        console.log(currentData)


        setpaperDimension(currentData)

    }

    const onChangeLargeSizeWidth= (e)=>{
          
        let currentData = paperDimension;

        currentData.large_marker_width = parseInt(e.target.value);

        console.log(currentData)


        setpaperDimension(currentData)

    }

    const onChangeLargeSizeHeight= (e)=>{
          
        let currentData = paperDimension;

        currentData.large_marker_height = parseInt(e.target.value);

        console.log(currentData)


        setpaperDimension(currentData)

    }


    const onChangePalletSizeHeight= (e)=>{
          
        let currentData = paperDimension;

        currentData.pallet_marker_height = parseInt(e.target.value);

        console.log(currentData)


        setpaperDimension(currentData)

    }

    const onChangePalletSizeDepth= (e)=>{
          
        let currentData = paperDimension;

        currentData.pallet_marker_depth = parseInt(e.target.value);

        console.log(currentData)


        setpaperDimension(currentData)

    }


    
    const onChangePalletSizeWidth= (e)=>{
          
        let currentData = paperDimension;

        currentData.pallet_marker_width = parseInt(e.target.value);

        console.log(currentData)


        setpaperDimension(currentData)

    }


    const onClickEditButton = (e)=>{
        setIsLoading(true)


        Request.UpdatepaperDimension(paperDimension).then((result)=>{
            setIsLoading(false)

            console.log(result)

            alert(result.data.msg)

        }).catch((error)=>{
            setIsLoading(false)
          
           


            setIsLoading(false)
            if(error.response.status === 401){
                dispatch(setAuth({user:null, access_token:null }))
        
              }else{
            
      
                console.log(error)
                alert("Something went wrong")
                }

        })

    }

    




  return (
    IsLoading?<Loader/>:
    <>
      <Navbar 
        scanned="Scanned data" 
        user="User master" 
        country="Country master" 
        warehouse="Warehouse master" 
        logout="Log Out"
        RealTime="realtime"
      />
      <div className="container  pt-50">
        <div className="row">
            <div className="col-md-4">
                <div className="size">
                    <p className='size-head'><b>Small Size</b></p>
                    <div className='form-group'>
                        <label className="" htmlfor="width">Width (mm)</label>
                        <input onChange={onChangeSmallSizeWidth} defaultValue={paperDimension.small_marker_width} className="inputClass form-control"/>
                    </div>
                    <div className='form-group'>
                        <label className="" htmlfor="depth">Depth (mm)</label>
                        <input onChange={onChangeSmallSizeDepth}  defaultValue={paperDimension.small_marker_depth} className="inputClass form-control"/>
                    </div>
                    <div className='form-group'>
                        <label className="" htmlfor="height">Height (mm)</label>
                        <input onChange={onChangeSmallSizeHeight} defaultValue={paperDimension.small_marker_height} className="inputClass form-control"/>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="size">
                    <p className='size-head'><b>Large Size</b></p>
                    <div className='form-group'>
                        <label className="" htmlfor="width">Width (mm)</label>
                        <input onChange={onChangeLargeSizeWidth} defaultValue={paperDimension.large_marker_width} className="inputClass form-control"/>
                    </div>
                    <div className='form-group'>
                        <label className="" htmlfor="depth">Depth (mm)</label>
                        <input  onChange={onChangeLargeSizeDepth} defaultValue={paperDimension.large_marker_depth} className="inputClass form-control"/>
                    </div>
                    <div className='form-group'>
                        <label className="" htmlfor="height">Height (mm)</label>
                        <input onChange={onChangeLargeSizeHeight}  defaultValue={paperDimension.large_marker_height} className="inputClass form-control"/>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="size">
                    <p className='size-head'><b>Pallet Size</b></p>
                    <div className='form-group'>
                        <label className="" htmlfor="width">Width (mm)</label>
                        <input onChange={onChangePalletSizeWidth} defaultValue={paperDimension.pallet_marker_width} className="inputClass form-control"/>
                    </div>
                    <div className='form-group'>
                        <label className="" htmlfor="depth">Depth (mm)</label>
                        <input onChange={onChangePalletSizeDepth} defaultValue={paperDimension.pallet_marker_depth}  className="inputClass form-control"/>
                    </div>
                    <div className='form-group'>
                        <label className="" htmlfor="height">Height (mm)</label>
                        <input onChange={onChangePalletSizeHeight} defaultValue={paperDimension.pallet_marker_height}  className="inputClass form-control"/>
                    </div>
                </div>
            </div>
        </div>
                <button onClick={onClickEditButton}  className="addnew"  >UPDATE</button>
      </div>
    </>
    
  )
}
