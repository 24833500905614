import React from 'react'
import delicon from '../image/del.png'
import Request from '../API/Requests';
import { setAuth } from '../store/authSlice';
import { useDispatch } from 'react-redux';
const DateFormatter = (created_at)=>{
  const date = new Date(created_at);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  if(created_at == null){
    return null
  }
  else{
  return `${year}-${month}-${day}`;
  }
}


export default function ScanDataTAbleHead({country_name, warehouse, type, product_name, width,
  depth, height, weight, qty, created_by, 
  created_at ,id,client_id,product_id}) {

    const dispatch = useDispatch();


    const onClickDelete = ()=>{
        
      Request.DeleteScanData({id}).then(()=>{
         window.location.reload(false);
      }).catch((error)=>{
        if(error.response.status === 401){
          dispatch(setAuth({user:null, access_token:null }))
           
        }
        else{
        console.log(error)
        alert("Something went wrong")  
      }
      })
  

}
  return (
    <>
        <tr>
            <th scope="row">
            <button onClick={onClickDelete} className="di"><img className="del" src={delicon} alt="del"/></button>
            </th>
            <td>
            <span className="stn">{country_name}</span>        
            </td>
            <td>{warehouse}</td>
            <td>{type}</td>
            <td>{product_id}</td>
            <td>{client_id}</td>
            <td>{width}</td>
            <td>{depth}</td>
            <td>{height}</td>
            <td>{weight}</td>
            <td>{qty}</td>
            <td>{created_by}</td>
            <td>{DateFormatter(created_at)}</td>
        </tr>
    </>
  )
}
