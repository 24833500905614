const regex = /\S+@\S+\.\S+/

export function ValidateEmail({ EmailList }){
      const EmailArray = EmailList.split(",");
       let finalResult = false;
      EmailArray.forEach((ele)=>{
          
        finalResult = regex.test(ele.trim())

      })

      return finalResult;
}