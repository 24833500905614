import React ,{ useEffect , useState } from 'react';
import ExelAdd from '../components/ExelAdd';
import Heading from '../components/Heading';
import TotalEntry from '../components/TotalEntry';
import Pagination from '../components/Pagination';
import WarehouseTableHead from '../components/WarehouseTableHead';
import WarehouseTableRow from '../components/WarehouseTableRow';
import Modal from '../components/Modal';
import Navbar from '../components/Navbar';
import Request from '../API/Requests';
import { useDispatch } from 'react-redux';
import { setAuth } from '../store/authSlice';
import Warehousemodal from '../components/Warehousemodal';
import Warehouseaddexcel from '../components/Warehouseaddexcel';
import Loader from '../components/Loader';
import { useSelector } from 'react-redux';


export default function Warehousemaster() {
    const dispatch = useDispatch();

    const {user} = useSelector((state)=> state.auth)
 
    const { ListWarehouse } = Request;
    const [warehouseList, setwarehouseList] = useState([]);
    
    const [name, setname] = useState('');
   const [editWareHouse, seteditWareHouse] = useState({})
   const [countryList, setcountryList] = useState([])

   const [IsLoading, setIsLoading] = useState(true)
   const [currentPage, setcurrentPage] = useState(1)
   const [max_page, setmax_page] = useState(1);
   const [totalRecords, settotalRecords] = useState(0)
   

//Listing  Country for Country Name and Id Mapping
   useEffect(() => {
 
    Request.GetAllCountry().then((result)=>{
         
   
         setcountryList(result.data.data)

         Request.ListWarehouse({ pageNumber:1 }).then((result)=>{
            
             setIsLoading(false)
             setmax_page(result.data.max_page)
            setwarehouseList(result.data.data)
            settotalRecords(result.data.total_record)
           
          
            //  setcountryMasterData(result.data.data)
        }).catch((error) => {
          if(error.response.status === 401){
            dispatch(setAuth({user:null, access_token:null }))
    
          }else{
      

            console.log(error)
            alert("Something went wrong")
            }
        })
         
         
    }).catch((error) => {
      if(error.response.status === 401){
        dispatch(setAuth({user:null, access_token:null }))

      }else{
      

        console.log(error)
        alert("Something went wrong")
        }
    })
      
  }, [])

  const onPageChange = ({ pageNumber })=>{
    Request.ListWarehouse({ pageNumber }).then((result)=>{
            
      setIsLoading(false)
     setwarehouseList(result.data.data)
     
   
     //  setcountryMasterData(result.data.data)
 }).catch((error) => {
   if(error.response.status === 401){
     dispatch(setAuth({user:null, access_token:null }))

   }else{
      

    console.log(error)
    alert("Something went wrong")
    }
 })
  }


  const getCountryId =( { country_name })=>{
    
    
         let country_id = null
        countryList.forEach((ele)=>{
            if(ele.country_name === country_name){
               
                country_id = ele.id
            }
        })

        return country_id
  }

 



// Listing WareHouse
    // useEffect(() => {

    //     Request.ListWarehouse().then((result)=>{
            
    //         setwarehouseList(result.data.data)
    //         console.log(result.data.data)
          
    //         //  setcountryMasterData(result.data.data)
    //     }).catch((error) => {
    //       if(error.response.status === 401){
    //         dispatch(setAuth({user:null, access_token:null }))
    
    //       }
    //       console.log(error)
    //     })
          
    //   }, [])
    
    
  return (
    IsLoading?<Loader/>:
    <>
    <Navbar 
        scanned="Scanned data" 
        user="User master" 
        country="Country master" 
        warehouse="Warehouse master" 
        logout="Log Out"
      />
    <div className="container pt-50">
        <div className="row">
            <div className="col-md-6">
                <Heading heading={"Warehouse master"}/>
            </div>
            <div className="col-md-6">
                <Warehouseaddexcel/>
            </div>
            <div className="col-md-12">
            <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                          <WarehouseTableHead/>
                        </thead>
                        <tbody>

                        
                            {
                                warehouseList.map((ele,i)=>{
                                    
                                return <WarehouseTableRow 
                                seteditWareHouse={seteditWareHouse}
                                country_id={getCountryId({country_name:ele.country_name})}
                                list={ele} key={i}/>
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="paginationcont">
                    <TotalEntry total={totalRecords} />
                    {<Pagination currentPage={currentPage} setcurrentPage={setcurrentPage} onPageChange={onPageChange} max_page={max_page} />}
                </div>
            </div>
        </div>
    </div>
    <Warehousemodal editWareHouse={editWareHouse} seteditWareHouse={seteditWareHouse} />
    </>
  )
}
