import React from 'react'
import { endpoint } from '../API/endpoint'
import { useSelector } from 'react-redux'
const ExelAdd = ({triggeringClass , exportPage}) => {
  const { scanDataExcel,userDataExcel,countryDataExcel } = endpoint

  const {isAuth , user} = useSelector((state)=> state.auth)
  let seletedURL = '';
  if(exportPage === "users"){
    seletedURL = `${endpoint.BaseUrl}${userDataExcel}?country_id=${user.role === 'super_admin'?0:user.country_id}&user_id=${user.id}`
    
  }
  else if(exportPage === "country"){
    seletedURL = `${endpoint.BaseUrl}${countryDataExcel}`
  }
  


  return (
    <div className="rightme">
      <div className="eab">
        {<button className="excel"><a  href={`${seletedURL}`} >EXCEL</a></button>}
        <button className="addnew" data-bs-toggle="modal" data-bs-target={`#${triggeringClass}`}>ADD NEW</button>
      </div>
    </div>
  )
}

export default ExelAdd