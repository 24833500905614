import React from 'react'

export default function WarehouseTableHead() {
  return (
    <tr>
        <th scope="col"></th>
        <th scope="col">Warehouse name</th>
        <th scope="col">Description</th>
        <th scope="col">Country</th>
        <th scope="col">Email</th>
        <th scope="col">Updated by</th>
        <th scope="col">Update date</th>
        <th scope="col">Created by</th>
        <th scope="col">Create date</th>
    </tr>
  )
}
