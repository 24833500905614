import React, { useState,useEffect,useRef } from 'react';
import top from '../image/Top.png';
import Request from '../API/Requests'
import {setAuth , setLoading} from '../store/authSlice'
import { useDispatch } from 'react-redux';

export default function Login() {
    

    const [userName, setuserName] = useState('');
    const [password, setpassword] = useState(''); 
    const dispatch = useDispatch(); 
    const LoginbuttonRef = useRef(null);
    
    
    const ClickLoginButton = async ()=>{
        dispatch(setLoading({ IsLoading: true }));

        try{
           const result = await Request.LoginApi({ username: userName, password: password})
           dispatch(setAuth(result.data))
           dispatch(setLoading({ IsLoading: false }));
           

        }
        catch(err){
            dispatch(setLoading({ IsLoading: false }));
            if(err.response.status === 401){
                alert("Please Provide Valid Email Address and Password")
            }
            if(err.response.status === 422){
                alert("Credentials do not match with our records.")
            }

        }
      
      }


      useEffect(() => {
        const keyDownHandler = event => {
          console.log('User pressed: ', event.key);
    
          if (event.key === 'Enter') {
            event.preventDefault();
    
            // 👇️ your logic here
            LoginbuttonRef.current.click()
          }
        };
    
        document.addEventListener('keydown', keyDownHandler);
    
        return () => {
          document.removeEventListener('keydown', keyDownHandler);
        };
      }, []);

    const onChangeUserName = (e)=>{
        setuserName(e.target.value);
    }
    const onChangePassword = (e)=>{
        setpassword(e.target.value);
    }


  return (
    <>
        <div className="container">
            <div className="row">
            <div className="colmd-12">
                <div className="logwrap">
                    <div className="logwrapinner">
                            <div className="mid">
                                    <div className="logo">
                                        <img src={top} alt="logo" className="toplogo" />
                                    </div>
                                    <div className="logform">
                                        <div className="logforminner">                  
                                            <div className="form-group">
                                            <label htmlFor="usr">Login ID</label>
                                            <input onChange={onChangeUserName} type="text" className="form-control" id="usr" />
                                            </div>
                                            <div className="form-group">
                                            <label htmlFor="pwd">Password:</label>
                                            <input onChange={onChangePassword} type="password" className="form-control" id="pwd" />
                                            </div>
                                            <p className="logtext">
                                            Please contact administrator if you forget your password.
                                            </p>
                                            <p className="submitbut">
                                            <button onClick={ClickLoginButton} ref={LoginbuttonRef} type="button" className="btn btn-mysuccess">Log in</button>
                                            </p>                 
                                        </div>                
                                    </div>
                            </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </>
  )
}


// REact meh for nai htmlhtmlFor use hota hai 