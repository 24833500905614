import { createSlice } from '@reduxjs/toolkit'


const initialState = {
     isAuth:(localStorage.getItem('AuthToken')? true:false),
     Loading:false,
    user:JSON.parse(localStorage.getItem('user'))
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state,action) => {
      const { user , access_token } = action.payload
      console.log(user)
      
      if(user == null){
        state.isAuth = false;
        state.user = '' ;
        localStorage.clear();
       
      }else{
        state.isAuth = true;
        state.user = user;
        localStorage.setItem('AuthToken',access_token)
        localStorage.setItem('user',JSON.stringify(user))
      }
      
      
      
    },
    setLoading : (state,action)=>{
         const { IsLoading } = action.payload;

         if(IsLoading === true){
          state.Loading = true;
         }
         else if(IsLoading === false){
          state.Loading = false;
         }
    }
  }
})

// Action creators are generated for each case reducer function
export const { setAuth , setLoading } = authSlice.actions

export default authSlice.reducer