import React from 'react'

const Tablehead = () => {
  return (
    <tr>
        <th scope="col"></th>
        <th scope="col">Staff ID</th>
        <th scope="col">Last name, First name</th>
        <th scope="col">Country</th>
        <th scope="col">Warehouse</th>
        <th scope="col">Password</th>
        <th scope="col">Role</th>
        <th scope="col">Updated by</th>
        <th scope="col">Update date</th>
        <th scope="col">Created by</th>
        <th scope="col">Create date</th>
    </tr>
  )
}

export default Tablehead
