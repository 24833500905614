import React from 'react'
import { useState } from 'react'
import Request from '../API/Requests'
import { setAuth } from '../store/authSlice'
import { useDispatch } from 'react-redux'

export default function Modal({editCountry , seteditCountry}) {

    const dispatch = useDispatch();

    
    const [newCountry, setnewCountry] = useState('')


    const onChangeNewCountry = (e)=>{
            setnewCountry(e.target.value); 
           
    }

    const onClickSaveChangesForNewCountry = async (e)=>{
          try {
            await Request.AddNewCountry({ country_name: newCountry})
            window.location.reload(false);
          } catch (error) {
            if(error.response.status === 401){
                dispatch(setAuth({user:null, access_token:null }))
        
              }
              console.log(error)
              alert(error.response.data.error)
          }
    }

    const onChangeEditCountry = async (e)=>{
          const id = editCountry.id;
          const name = editCountry.country_name

          seteditCountry({
            country_name:e.target.value,
            id:id
          })
    }

    const onClickSaveEditChanges = async ()=>{
        try {
             
            await Request.UpdateCountry({ country_name: editCountry.country_name , id: editCountry.id})
            window.location.reload(false);

        } catch (error) {
            if(error.response.status === 401){
                dispatch(setAuth({user:null, access_token:null }))
        
              }
              console.log(error)
              alert(error.response.data.error)
        }
    }
    
    
    return (
        
    <>
      {/* modal user master*/ }
      
        <div className="modal fade" id="usermaster" tabIndex="-1" aria-labelledby="usermaster" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable">
            <div className="modal-content">
                <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">Edit</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label htmlFor="staffid" className="form-label">Staff ID</label>
                                <input type="text" className="form-control" id="staffid" placeholder=""/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label htmlFor="firstandlastname" className="form-label">Last name, First name</label>
                                <input type="text" className="form-control" id="firstandlastname" placeholder=""/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label htmlFor="sountry" className="form-label">Country</label>
                                <select className="form-select" aria-label="Default select example">
                                    <option value="0">China</option>
                                    <option value="1">China</option>
                                    <option value="2">China</option>
                                    <option value="3">China</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label htmlFor="warehouse" className="form-label">Warehouse</label>
                                <select className="form-select" aria-label="Default select example">
                                    <option value={0}>Warehouse 1</option>
                                    <option value="1">Warehouse 1</option>
                                    <option value="2">Warehouse 1</option>
                                    <option value="3">Warehouse 1</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label htmlFor="password" className="form-label">Password</label>
                                <input type="text" className="form-control" id="password" placeholder=""/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label htmlFor="role" className="form-label">Role</label>
                                <input type="text" className="form-control" id="role" placeholder=""/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label htmlFor="updatedby" className="form-label">Updated by</label>
                                <input type="text" className="form-control" id="updatedby" placeholder=""/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label htmlFor="createdby" className="form-label">Created by</label>
                                <input type="text" className="form-control" id="createdby" placeholder=""/>
                            </div>
                        </div>
                        <div className="col-md-6">                        
                            <div className="mb-2">
                                <label htmlFor="updatedate" className="form-label">Update date</label>
                                <input type="date" className="form-control" id="updatedate" placeholder=""/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label htmlFor="createdate" className="form-label">Create date</label>
                                <input type="date" className="form-control" id="createdate" placeholder=""/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary">Save changes</button>
                </div>
            </div>
            </div>
        </div>
        {/* end */}


         {/* modal countery master ADD */}
         <div className="modal fade" id="addcountry" tabIndex="-1" aria-labelledby="countrymaster" aria-hidden="true">
         <div className="modal-dialog modal-dialog-scrollable">
         <div className="modal-content">
             <div className="modal-header">
             <h1 className="modal-title fs-5" id="exampleModalLabel">Edit</h1>
             <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
             </div>
             <div className="modal-body">
                 <div className="row">
                     <div className="col-md-12">
                         <div className="mb-2">
                             <label htmlFor="sountry" className="form-label">Country</label>
                             <input type="text" className="form-control" id="sountry" placeholder="" onChange={onChangeNewCountry}   />
                             
                         </div>
                     </div>
                    
                     
                    
                     
                 </div>
             </div>
             <div className="modal-footer">
             <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
             <button type="button" onClick={onClickSaveChangesForNewCountry} className="btn btn-primary">Save changes</button>
             </div>
         </div>
         </div>
     </div>
     {/* end */}

        {/* modal countery master Edit */}
        <div className="modal fade" id="countrymaster" tabIndex="-1" aria-labelledby="countrymaster" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable">
            <div className="modal-content">
                <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">Edit</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-2">
                                <label htmlFor="sountry" className="form-label">Country</label>
                                <input type="text" className="form-control" id="sountry"  value={editCountry.country_name} onChange={onChangeEditCountry}  />
                            </div>
                        </div>
                       
                        
                        
                        
                    </div>
                </div>
                <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button onClick={onClickSaveEditChanges} type="button" className="btn btn-primary">Save changes</button>
                </div>
            </div>
            </div>
        </div>
        {/* end */}
        
        {/* modal Warehouse master*/}
        <div className="modal fade" id="warehousemaster" tabIndex="-1" aria-labelledby="warehousemaster" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable">
            <div className="modal-content">
                <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">Edit</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label htmlFor="warehouse" className="form-label">Warehouse name</label>
                                <select className="form-select" aria-label="Default select example">
                                    <option value={0}>Warehouse 1</option>
                                    <option value="1">Warehouse 1</option>
                                    <option value="2">Warehouse 1</option>
                                    <option value="3">Warehouse 1</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label htmlFor="sountry" className="form-label">Country</label>
                                <select className="form-select" aria-label="Default select example">
                                    <option value={0}>China</option>
                                    <option value="1">China</option>
                                    <option value="2">China</option>
                                    <option value="3">China</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-2">
                                <label htmlFor="description" className="col-form-label">Description</label>
                                <textarea className="form-control" id="Description"></textarea>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="mb-2">
                                <label htmlFor="warehouseemail" className="form-label">Email</label>
                                <input type="text" className="form-control" id="warehouseemail" placeholder=""/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label htmlFor="updatedby" className="form-label">Updated by</label>
                                <input type="text" className="form-control" id="updatedby" placeholder=""/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label htmlFor="createdby" className="form-label">Created by</label>
                                <input type="text" className="form-control" id="createdby" placeholder=""/>
                            </div>
                        </div>
                        <div className="col-md-6">                        
                            <div className="mb-2">
                                <label htmlFor="updatedate" className="form-label">Update date</label>
                                <input type="date" className="form-control" id="updatedate" placeholder=""/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-2">
                                <label htmlFor="createdate" className="form-label">Create date</label>
                                <input type="date" className="form-control" id="createdate" placeholder=""/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary">Save changes</button>
                </div>
            </div>
            </div>
        </div>
        {/* end */}
    </>
  )
}



// {country_name ,updated_by , updated_at,created_at,created_by }