import React ,{ useEffect , useState } from 'react';
import Heading from '../components/Heading';
import ExelAdd from '../components/ExelAdd';
import Tablehead from '../components/Tablehead';
import TableRow from '../components/TableRow';
import Pagination from '../components/Pagination';
import TotalEntry from '../components/TotalEntry';
import Modal from '../components/Modal';
import Navbar from '../components/Navbar';
import Request from '../API/Requests';
import { useDispatch } from 'react-redux';
import { setAuth } from '../store/authSlice';
import Loader from '../components/Loader';
import UserModalAdd from '../components/UserModalAdd';
import UserModalEdit from '../components/UserModalEdit';



export default function Usermaster() {
    // const usermasterdata = [1,2,3,4,5,6]
    
    const [wareHouseData, setwareHouseData] = useState([])
    const dispatch = useDispatch();
    const [IsLoading, setIsLoading] = useState(true)
    const [userMasterData, setuserMasterData] = useState([])
    const [currentPage, setcurrentPage] = useState(1)
    const [max_page, setmax_page] = useState(1);
    const [editUserData, seteditUserData] = useState({
        wareHouseArray:[],
        first_name:'',
        last_name:'',
        staff_id:'',
        country_id:'',
        warehouse_id:'',
        password:'',
        role:'',
        confirm_password:'',
            })

            const [totalRecords, settotalRecords] = useState(0)
            

    useEffect(()=>{
        Request.ListUsers({ pageNumber:1 }).then((result)=>{
            
            setIsLoading(false)
           
            setmax_page(result.data.max_page)
            setuserMasterData(result.data.data)
            settotalRecords(result.data.total_record)

        }).catch((error) => {
            if(error.response.status === 401){
                dispatch(setAuth({user:null, access_token:null }))
        
              }else{
      

                console.log(error)
                alert("Something went wrong")
                }
        })

        Request.GetAllWareHouse().then((res)=>{
            setwareHouseData(res.data.data)
           
            // onChangeCountry_Warehouse({id:editUserData.country_id , wareHouseData:res.data.data})
        }).catch((error) => {
            if(error.response.status === 401){
                dispatch(setAuth({user:null, access_token:null }))
                // dispatch(setLoading({ IsLoading: false }));
              }else{
            
      
                console.log(error)
                alert("Something went wrong")
                }
        })
        
    },[])

    const onPageChange = ({pageNumber})=>{
        Request.ListUsers({ pageNumber }).then((result)=>{
            
            setIsLoading(false)
            
            setuserMasterData(result.data.data)

        }).catch((error) => {
            if(error.response.status === 401){
                dispatch(setAuth({user:null, access_token:null }))
        
              }else{
      

                console.log(error)
                alert("Something went wrong")
                }
        })
        
        

    }


    


  return (
    IsLoading?<Loader/>:
    <>
    <Navbar 
        scanned="Scanned data" 
        user="User master" 
        country="Country master" 
        warehouse="Warehouse master" 
        logout="Log Out"
      />
    <div className="container  pt-50">
        <div className="row">
            <div className="col-md-6 col-sm-6">
              <Heading heading={"User master"}/>
            </div>
            <div className="col-md-6 col-sm-6">
                <div className="rightme">
                    <ExelAdd triggeringClass={"usermasteradd"} exportPage={"users"} />
                </div>               
            </div>
            <div className="col-md-12">
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                          <Tablehead/>
                        </thead>
                        <tbody>
                            {
                                userMasterData.map((ele,i)=>{
                                return <TableRow setwareHouseData={setwareHouseData} wareHouseData={wareHouseData}  userid={ele.id}  country_id={ele.country_id} warehouse_id={ele.warehouse_id} first_name={ele.first_name} staff_id={ele.staff_id} last_name={ele.last_name} password={ele.password} country_name={ele.country_name} warehouse={ele.warehouse}  role={ele.role} updated_at={ele.updated_at} updated_by={ele.updated_by} created_at={ele.created_at} created_by={ele.created_by} key={i} id={ele.id} seteditUserData={seteditUserData}/>
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="paginationcont">
                    <TotalEntry total={totalRecords}/>
                    <Pagination currentPage={currentPage} setcurrentPage={setcurrentPage} onPageChange={onPageChange} max_page={max_page}/>
                </div>
                
            </div>
        </div>
    </div>
    <UserModalAdd  />
    <UserModalEdit editUserData={editUserData} seteditUserData={seteditUserData} />
    
    </>
  )
}
