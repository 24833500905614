import React from 'react'

export default function Pagination({ currentPage , setcurrentPage ,onPageChange  ,max_page  }) {



    
   
    const onClickNext = ()=>{
        
        if(!(currentPage + 4 > max_page)){
        setcurrentPage((prev)=>{
            return prev+4;
        })
    }
    else{
        for( let i=4; i>1; i-- ){
             if(((currentPage + i)+3)<=max_page){
                setcurrentPage(currentPage+i)
                break;
             }
        }
    }
    }

    const onClickPrev = ()=>{
        if(currentPage >= 1 && (currentPage-4) >=1){
        setcurrentPage((prev)=>{
            return prev-4;
        })
    }
    else{
       setcurrentPage(1)
    }
    }

    const onClickFirst = ()=>{
        
        onPageChange({ pageNumber: currentPage})
    }

    const onClickSecond = ()=>{
        onPageChange({ pageNumber: currentPage+1})
    }

    const onClickThird = ()=>{
        onPageChange({ pageNumber: currentPage+2})
    }

    const onClickFourth = ()=>{
        onPageChange({ pageNumber: currentPage+3})
    }

    const onClickLast = ()=>{
        onPageChange({ pageNumber: 1})
        setcurrentPage(1)
    }

    const onClickMostForward = ()=>{
        onPageChange({ pageNumber:max_page})
        setcurrentPage(max_page)
    }


    

    


  return (        
        <nav aria-label="Page navigation example">
            <ul className="pagination">
                <li className="page-item">
                <button onClick={onClickLast}  className="page-link"  aria-label="Previous">
                <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                </button>
                </li>
                <li className="page-item">
                <button onClick={onClickPrev} className="page-link" href="#">
                    <i className="fa fa-angle-left" aria-hidden="true"></i>
                </button>
                </li>
                <li className="page-item"><button className="page-link" onClick={onClickFirst} >{currentPage}</button></li>
                {currentPage+1>max_page?"":<li className="page-item"><button className="page-link" onClick={onClickSecond}  >{currentPage+1}</button></li>}
                {currentPage+2>max_page?"":<li className="page-item"><button className="page-link" onClick={onClickThird}  >{currentPage+2}</button></li>}
                {currentPage+3>max_page?"":<li className="page-item"><button className="page-link" onClick={onClickFourth}  >{currentPage+3}</button></li>}
                <li className="page-item">
                <button onClick={onClickNext} className="page-link" href="#">
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                </button>
                </li>
                <li className="page-item">
                <button  onClick={onClickMostForward} className="page-link"  aria-label="Next">
                   <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                </button>
                </li>
            </ul>
        </nav>
  )
}
